import { PageProps, graphql } from 'gatsby'
import Articles from '../../components/Articles/Articles'
import Seo from '../../components/seo'
import '../style.scss'
import BlogLayout from '../../components/BlogLayout/BlogLayout'
import { getAuthorObjectList } from '../../utils/author_helper'
import { ArticleCardProps } from '../../components/Articles/ArticleCard/ArticleCard'

interface IPageContext {
  tag: string
}

const BlogTagPage = ({
  data,
  pageContext,
}: PageProps<
  Queries.BlogPostsByTagQuery,
  {
    tag: string
  }
>) => {
  const tag = pageContext?.tag
  const authorMap = pageContext['authorMap']
  const articles: ArticleCardProps[] = data?.allMarkdownRemark.nodes.map(
    (post) => {
      return {
        slug: post.fields?.slug || '',
        authors: getAuthorObjectList(authorMap[post.fields?.slug] || []),
        date: post.frontmatter?.date || '',
        title: post.frontmatter?.title || '',
        category: post.frontmatter?.category || '',
        description: post.frontmatter?.description || '',
        thumbnailImage: post.frontmatter?.thumbnailImage || null,
      }
    }
  )

  return (
    <BlogLayout>
      <Articles filterTitle={`Tag: ${tag.toLowerCase()}`} articles={articles} />
    </BlogLayout>
  )
}

export default BlogTagPage

export const Head = ({ pageContext }: { pageContext: IPageContext }) => {
  return <Seo title={`Tag: ${pageContext.tag} | PennyLane Blog`} />
}

export const pageQuery = graphql`
  query BlogPostsByTag($tag: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { tags: { in: [$tag] } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          authors {
            name
            nameSuffix
          }
          tags
          category
          description
          thumbnailImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
